export const translate={   
    "LIKE":{
        "zh":"讚好"
    },
    "Curator / Dom":{
        "zh":"創作人 / 主"
    },
    "Model / Sub":{
        "zh":"麻豆 / 奴"
    },
    "Copied":{
        "zh":"已複製"
    },
    "Album":{
        "zh":"相簿"
    },
    "Contact":{
        "zh":"聯絡"
    },
    "Albums":{
        "zh":"相簿"
    },
    "Apply":{
        "zh":"報名"
    },
    "About":{
        "zh":"關於"
    },
    "Close":{
        "zh":"完成"
    },
    "Apply for a session":{
        "zh":"報名一起玩"
    },
    "Done":{
        "zh":"完成"
    },
    "Profile":{
        "zh":"檔案"
    },
    "Search":{
        "zh":"搜尋"
    },
    "Cancel":{
        "zh":"完成"
    },
    "Apply Now":{
        "zh":"現在就報名"
    },
    "Other enquiries":{
        "zh":"其他查詢"
    },
    "Pop me a message":{
        "zh":"發訊息"
    },
    "Email":{
        "zh":"電郵"
    },
    "Kink":{
        "zh":"性趣"
    },
    "Theme":{
        "zh":"主題"
    },
    "Filters":{
        "zh":"選項"
    },
    "Language":{
        "zh":"語言"
    },
    "Latest":{
        "zh":"最新"
    },
    "Sort By":{
        "zh":"排序"
    },
    "You and":{
        "zh":"你和其他"
    },
    "JIZ'd":{
        "zh":"人已射"
    },
    "Most Viewed":{
        "zh":"觀看次數"
    },
    "Most Liked":{
        "zh":"讚好次數"
    },
    "Learn about me":{
        "zh":"先了解一下"
    },
    "Contact me through email if you don't have Twitter":{
        "zh":"如果你沒有推特，可以用電郵聯絡"
    },
    "Start your conversation with something more than 'hi' 🙂":{
        "zh":"請直接說明來意，用 “hi” 以外的方法打開話題🙂"
    },
    "Questions about uncensored media, personal details of models will not be responded.":{
        "zh":"有關無碼全見 / 完整視頻 / 麻豆個人資料 等的問題一律不作回答"
    },
    "Please drop your question in full and directly on Twitter.":{
        "zh":"請直接在推特 DM 裡面留下你的問題"
    },
    "Both Dom and Sub are welcomed":{
        "zh":"主和奴都歡迎聯絡遊玩交流"
    },
    "Provide a few details and let me get back to you.":{
        "zh":"請留下一點有關你的資料，我會儘快給你回覆"
    },
    "Hello, I am ____. Would like to ask ":{
        "zh":"你好，我是_____，想請問..."
    },
    "Video Quality":{
        "zh":"影片質量"
    }
}
