import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonButton,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonSpinner,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
  useIonToast
} from '@ionic/react';
import { bag, card, eye, logoInstagram, logoTwitter } from 'ionicons/icons';

import { IonReactRouter } from '@ionic/react-router';
import { constructOutline, ellipse, globeOutline, language, square, triangle } from 'ionicons/icons';
import Tab1 from './pages/Tab1';
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';
import Welcome from './pages/Welcome';
import Homepage from './pages/Homepage';
import { useCookies } from 'react-cookie'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import React, { useState, useMemo, createContext, useEffect, useContext } from 'react';

import AlbumView from './pages/AlbumView';
import ProfileView from './pages/ProfileView';

import config from "./config.json";
import {translate} from "./translate";
import axios from 'axios';

//console.log(navigator.platform.toLocaleLowerCase())
if(navigator.platform.toLocaleLowerCase().indexOf('android')>-1 ||
navigator.platform.toLocaleLowerCase().indexOf('linux')>-1 || 
navigator.platform.toLocaleLowerCase().indexOf('cros')>-1 ){
  //console.log('material')
  setupIonicReact()
}else{
  //console.log('ios')
  setupIonicReact({ mode: 'ios' })
}


export const LanguageContext = React.createContext({
  lang:'en',
  setLang:null
});
export const VideoQualityContext = React.createContext({
  videoQuality:854,
  setVideoQuality:null
});
export const AppContext = React.createContext({
  appContext:{
    kink:{},
    profile:{},
    dom:[],
    sub:[],
    admin:""
  },
  setAppContext:null
});


export const sendGetRequest = async (method,url,data={}) => {
  const response = await axios({
    method,
    url,
    headers: {},
    data: data,
    withCredentials: true
  });
  return response.data;
};




export const T8 = (lang:string,key:string,fallback:string="") => {

  return translate?.[key]?.[lang] || translate?.[key]?.['en'] || fallback || (lang!='en' && console.log(key) )|| (""+key+"");
};



const App: React.FC = () => {

  const [cookies, setCookie] = useCookies(['regionApproved', 'language'])

  console.log('Cookies',cookies)
  const [lang,setLang]=useState( navigator.language.indexOf('zh-')>-1?'zh':'en');
  const languageProvider = useMemo( () => {
    return ({lang,setLang})
  }, [lang,setLang])


  const [videoQuality,setVideoQuality]=useState(854);
  const videoQualityProvider = useMemo( () => {
    return ({videoQuality,setVideoQuality})
  }, [videoQuality,setVideoQuality])


  const [appContext,setAppContext]=useState({
    kink:{},
    profile:{},
    dom:[],
    sub:[],
    admin:""
  });
  const appContextProvider = useMemo( () => {
    return ({appContext,setAppContext})
  }, [appContext,setAppContext])

  const [present] = useIonToast();
  useEffect(() => {
    setLoaded(false);
    sendGetRequest('get',config[process.env.NODE_ENV].ENDPOINT+'/app_dict').then(data => {
        data.admin=false;
        setAppContext(data);
        setRegionApproved(data?.regionApproved||cookies.regionApproved||false)
        if(cookies?.language=='en' || cookies?.language=='zh'){
          setLang(cookies?.language)
        }
        setLoaded(true)
    }).catch(e => {

      present({
        message: T8(lang,'Service unavailable'),
        duration: 15000,
        position: 'bottom',
        cssClass: 'errorToast',
        icon: 'warning'
      });
    });
  }, []);
  const [loaded, setLoaded] = useState(false);
  const [regionApproved, setRegionApproved] = useState(Boolean(cookies?.regionApproved) || false);
  
  return(
    <LanguageContext.Provider value={languageProvider}>   
    <VideoQualityContext.Provider value={videoQualityProvider}>    
      <AppContext.Provider value={appContextProvider}>
        <IonApp>
        {loaded && regionApproved?
        <IonReactRouter>
        <IonRouterOutlet>
        <Route exact path="/">
            <Redirect to="/welcome" />
        </Route>
        <Route path="/welcome">
          <Welcome />
        </Route>
        
          <Route path="/album/:id">
          <AlbumView />
        </Route>
        <Route path="/profile/:id">
          <ProfileView />
        </Route>
        <Route path="/profile/:id/:role">
          <ProfileView />
        </Route>
        <Route path="/app">
          <Homepage />
        </Route>
        <Route path='/apply' exact component={() => {
              window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSf1mXIYRcYFp7BdaWPRMrElFEjTy-rCaHtwgqCicD7ohIq1Ug/viewform?usp=sf_link';
              return null;
        }}/>
        
        </IonRouterOutlet>
        </IonReactRouter>
       :
        <center>
        {!loaded && <>
          <div className='ion-padding'>
          
          <IonSpinner></IonSpinner>
          <br></br>
          <IonButton className="bottom-button" fill="outline" href='https://ig.me/m/jizbar.rope?ref=w1968814'>Instagram</IonButton><br></br>
          <br></br>
          <IonButton className="bottom-button" fill="outline" href='https://twitter.com/jizbar'>Twitter</IonButton><br></br>
          <IonButton className="bottom-button"  fill="outline" href='https://docs.google.com/forms/d/e/1FAIpQLSf1mXIYRcYFp7BdaWPRMrElFEjTy-rCaHtwgqCicD7ohIq1Ug/viewform?usp=sf_link'>{("Apply for Session")}</IonButton>

        </div>
          </>}
        
          {loaded && !regionApproved && <>
            <div className='ion-padding' style={{
                 background:"linear-gradient(107deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.8) 100%),url('../../assets/bg.jpg')",
                 backgroundSize:"cover",
                 backgroundPosition: "50% 50%",
                position:"fixed",
                top:"0",
                left:"0",
                width:'100%',
                height:'100%'
            }} >
            <br></br><br></br>
            <IonIcon icon={globeOutline} size="large"></IonIcon>
            <br></br>
            <h3>Your region is not supported</h3>
            <h3>未支援你所在的地區</h3>
            <br></br>
            <br></br>
            <br></br>
            <p style={{fontSize:'0.8em'}}>Please select from the list of regions below if we have wrongly identified your region</p>
            <p style={{fontSize:'0.8em'}}>如你認為我們錯誤地辨認你所在地區，請從以下地區列表選擇</p><br></br><br></br><br></br>            
            <select style={{fontSize:"1.2em", padding:"10px 20px"}} onChange={e=>{
              if(e.target.value!=="hk"){
                setCookie('regionApproved',true, { path: '/',  expires:new Date(new Date().getTime() + (365 * 86400 * 1000)) });
                setRegionApproved(true)
              }else{
                present({
                  message: "Region not supported // 不支援此地區",
                  duration: 15000,
                  position: 'bottom',
                  cssClass: 'errorToast',
                  icon: 'warning'
                });
              }
              
            }}>
              <option value='hk'>Select / 請選擇</option>
              <option value='tw'>Taiwan / 台灣</option>
              <option value='cn'>China / 中國</option>
              <option value="hk">Hong Kong / 香港</option>
              <option value='jp'>Japan / 日本</option>
              <option value='sg'>Singapore / 星加坡</option>
              <option>United States</option>
              <option>United Kingdom</option>
              <option>Others / 其他</option>
            </select>
            <br></br>
            <br></br><br></br><br></br>
                <IonButton className="bottom-button"  size='small' fill="clear" href='https://twitter.com/jizbar'><IonIcon icon={logoTwitter}></IonIcon></IonButton>
            <IonButton className="bottom-button" style={{background:"linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)", color:"#FFF",borderRadius:"5px"}}  size='small' fill="outline" href='https://ig.me/m/jizbar.rope?ref=w19688148' target='_blank'><IonIcon icon={logoInstagram} style={{marginRight:"10px"}}></IonIcon> Follow on Instagram</IonButton>
                <br></br><br></br>
                <IonButton className="bottom-button"  size='small' fill="outline" href='https://www.recon.com/jizbar'><img src="./assets/icon/recon.png" style={{height:"15px", marginRight:"10px"}}></img> Recon</IonButton>
                <IonButton className="bottom-button"  size='small' fill="outline" href='https://switchedapp.com/@JIZBAR'><img src="./assets/icon/switchedapp.png" style={{height:"15px", marginRight:"10px"}}></img> Switched</IonButton>
                <IonButton className="bottom-button"  size='small' fill="outline" href='https://docs.google.com/forms/d/e/1FAIpQLSf1mXIYRcYFp7BdaWPRMrElFEjTy-rCaHtwgqCicD7ohIq1Ug/viewform?usp=sf_link'>{T8(lang,"Apply for Session")}</IonButton>


          </div>
            </>}
          
        </center>
      }
      </IonApp>
      </AppContext.Provider> 
    </VideoQualityContext.Provider>
  </LanguageContext.Provider>
  );
};


export default App;
