import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { square, wallet, person, star, information, chatboxEllipsesOutline, apertureOutline, informationOutline } from 'ionicons/icons';
import { useContext } from 'react';
import { Redirect, Route } from 'react-router';
import { LanguageContext, T8 } from '../App';
import Tab1 from './Tab1';
import Tab2 from './Tab2';
import Tab3 from './Tab3';

const Homepage: React.FC = () => {
  const {lang} = useContext(LanguageContext)
  return (
    <IonTabs>
      <IonRouterOutlet>
        
        <Route exact path="/app">
          <Redirect  to="/app/album" />
        </Route>
        <Route path="/app/album">
          <Tab1 />
        </Route>
        <Route exact path="/app/about">
          <Tab2 />
        </Route>
        <Route exact path="/app/contact">
          <Tab3 />
        </Route>
      </IonRouterOutlet>
      
      <IonTabBar slot="bottom">
        <IonTabButton tab="tab1" href="/app/album">
          <IonIcon icon={apertureOutline} />
          <IonLabel>{T8(lang,"Albums")}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="tab2" href="/profile/jizbar">
          <IonIcon icon={informationOutline} />
          <IonLabel>{T8(lang,"About")}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="tab3" href="/app/contact">
          <IonIcon icon={chatboxEllipsesOutline} />
          <IonLabel>{T8(lang,"Contact")} / {T8(lang,"Apply")}</IonLabel>
        </IonTabButton>
        
      </IonTabBar>
      
    </IonTabs>

  );
};

export default Homepage;
