import { IonButton, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonPage, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
//import './Welcome.css';
import { bag, card, eye, logoInstagram, logoTwitter } from 'ionicons/icons';
import { AppContext, LanguageContext, T8 } from '../App';
import { useContext } from 'react';
import './Welcome.css';
import { useCookies } from 'react-cookie';

const Welcome: React.FC = () => {
  const [cookies, setCookie] = useCookies(['regionApproved', 'language'])
    const {lang,setLang}=useContext(LanguageContext);
    const {appContext,setAppContext}=useContext(AppContext);
  return (
    <IonPage >
      <IonContent fullscreen>
                <div className='container'>
                    <div className='content'>
                        <img src='/assets/icon/jizbar_logo.svg' style={{width:'300px'}} /><br></br>
                        <IonText>Let's pl<span onContextMenu={e=>{
                        let temp=appContext
                        temp.admin=prompt("Admin Code?");
                        setAppContext(temp)
                    }}>a</span>y ;)</IonText>
                        <br></br>
    <div style={{fontSize:'0.7em'}} className="ion-padding">
        <p style={{fontSize:'1em'}}  color={"medium"}>此網站載有同性戀及性癖好的色情物，未滿法定成年禁止進入</p>
        <p  style={{fontSize:'1em'}}  color={"medium"}>Gay and kinky erotic materials ahead, do not enter if you are under legal age.</p>
        <p style={{opacity:0.01, fontSize:'0.2em'}}>{process.env.NODE_ENV}</p>
    </div> 
                    </div>
                <IonButton onClick={e=>{setLang('en');setCookie('language','en')}} style={{padding:'2em auto'}} size="large" fill="solid" className="" routerDirection='root' routerLink='/app'><div> Enter </div></IonButton>
                {true && <IonButton  onClick={e=>{setLang('zh');setCookie('language','zh')}} style={{padding:'2em auto'}} size="large" fill="solid" className="" routerDirection='root' routerLink='/app'> 進 入 </IonButton>}
                <br></br><br></br>
                <IonButton className="bottom-button"  size='small' fill="clear" href='https://twitter.com/jizbar'><IonIcon icon={logoTwitter}></IonIcon></IonButton>
                <IonButton className="bottom-button" style={{background:"linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)", color:"#FFF",borderRadius:"5px"}}  size='small' fill="outline" href='https://ig.me/m/jizbar.rope?ref=w19688148' target='_blank'><IonIcon icon={logoInstagram} style={{marginRight:"10px"}}></IonIcon> Follow on Instagram</IonButton>
                <br></br><br></br>
                <IonButton className="bottom-button"  size='small' fill="outline" href='https://www.recon.com/jizbar'><img src="./assets/icon/recon.png" style={{height:"15px", marginRight:"10px"}}></img> Recon</IonButton>
                <IonButton className="bottom-button"  size='small' fill="outline" href='https://switchedapp.com/@JIZBAR'><img src="./assets/icon/switchedapp.png" style={{height:"15px", marginRight:"10px"}}></img> Switched</IonButton>
                <IonButton className="bottom-button"  size='small' fill="outline" href='https://docs.google.com/forms/d/e/1FAIpQLSf1mXIYRcYFp7BdaWPRMrElFEjTy-rCaHtwgqCicD7ohIq1Ug/viewform?usp=sf_link'>{T8(lang,"Apply for Session")}</IonButton>
                <br></br><br></br>

                </div>
                <div className='welcomepage_bg'></div>
      </IonContent>
    </IonPage>
  );
};

export default Welcome;
