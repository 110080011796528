import { IonSpinner, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonIcon, IonCardContent, IonGrid, IonCol, IonRow, IonText, useIonToast, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';
import axios from 'axios';
import { personOutline, handLeftOutline, heart, heartOutline, eyeOutline, alertCircleOutline } from 'ionicons/icons';
import { useContext, useEffect, useState } from 'react';
import { AppContext, LanguageContext, sendGetRequest, T8 } from '../App';

import config from "../config.json";
import './ExploreContainer.css';
import '../pages/Tab1.css';
import LazyLoad from 'react-lazy-load';
interface ContainerProps {
  name?: string,
  kink?: string[],
  dom?: string[],
  sub?: string[],
  sort?:string
}

const SearchResults: React.FC<ContainerProps> = ({ name="", kink=[], dom=[], sub=[], sort='date'}) => {

    const {appContext}= useContext(AppContext);
    const [contentLength, setContentLength] = useState(getInitContentLength())

    const [present] = useIonToast();

    function getInitContentLength(){
      let itemsize=350*250;
      let contentLength=(window.innerHeight*window.innerWidth)/(itemsize)
      return contentLength<12?12:contentLength
    }

    useEffect(() => {
        setItems([]);
        setLoaded(false);
        sendGetRequest('post',config[process.env.NODE_ENV].ENDPOINT+'/getAlbums',{
            query: name,
            kink,
            dom,
            sub,
            sort
        }).then(data => {
            setItems(data);
            setLoaded(true)
        }).catch(error => {
              present({
                message: 'Service unavailable',
                duration: 15000,
                position: 'bottom',
                cssClass: 'errorToast',
                icon: 'warning'
              });
        });
      }, [name,JSON.stringify(kink),JSON.stringify(dom),JSON.stringify(sub),sort]);
      const [items, setItems] = useState([]);
      const [loaded, setLoaded] = useState(false);
      const {lang}=useContext(LanguageContext);

      function mapUserID(arr){
        var output=[]
        for(var i=0;i<arr.length;i++){
            output.push(appContext.profile?.[arr[i]]?.['name']?.[lang] || "" )
        }

        return output.join(', ')
      }
  return (

    <>
         {!loaded && <center><IonSpinner></IonSpinner></center>}

          {items.length == 0 && loaded ?
            <center><div style={{padding:"20px"}}> <IonIcon icon={alertCircleOutline}></IonIcon><br></br>{T8(lang,"No Results")}</div></center> :
            <div className='grid'>
            {
            items.slice(0,contentLength).map((item) => (<div className='grid_item'><LazyLoad><IonCard key={item.id} routerLink={"/album/"+item.album_id} routerDirection="forward">
               <div className={item.cover && "headerImage"} 
                    style={{backgroundImage:"url('"+(config[process.env.NODE_ENV].SD_DIR+item.cover)+"')"}}>
              
              <IonCardHeader className='dimblurBackground'>
                {item.title[lang] && <IonCardTitle>{item.title[lang]}</IonCardTitle>}
                <div color="medium" style={{fontSize:"0.7em", lineHeight:"2em"}}>
                    {new Date(item.timestamp*1000).toISOString().split("T")[0]} · 
                     <IonText color="medium"> <><IonIcon icon={heartOutline}></IonIcon></> {item['likes']}</IonText> · 
                     <IonText color="medium"> <><IonIcon icon={eyeOutline}></IonIcon></> {item['views']}</IonText> 
                </div>
                <IonGrid className="noPadding no-padding ion-no-padding"><IonRow>
                    {item.sub.length>0 &&<IonCol><IonCardSubtitle><IonIcon icon={personOutline}></IonIcon> {mapUserID(item.sub)}</IonCardSubtitle></IonCol> }   
                    {item.dom.length>0 && <IonCol><IonCardSubtitle><IonIcon icon={handLeftOutline}></IonIcon> {mapUserID(item.dom)}</IonCardSubtitle></IonCol> }   
                </IonRow></IonGrid>
                {item.description[lang] &&<div color="medium" style={{fontSize:"0.9em", lineHeight:"1.5em"}}>{item.description[lang]}</div>}

                
              </IonCardHeader>
              </div>
            </IonCard>
              </LazyLoad></div>))
              }
              </div>

          }


          {loaded && contentLength<items.length &&<>
            <IonInfiniteScroll
            onIonInfinite={(ev) => {
              setContentLength(contentLength+12)
              setTimeout(() => ev.target.complete(), 100);
            }}
          >
            <IonInfiniteScrollContent></IonInfiniteScrollContent>
          </IonInfiniteScroll>
          </>}
          </>
  );
};
export default SearchResults;


          