import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonPage, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonSlide, IonSlides, IonSpinner, IonText, IonTitle, IonToolbar } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Tab1.css';
import './Welcome.css';
import { useHistory, useLocation } from 'react-router';
import { useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { AppContext, LanguageContext, T8, VideoQualityContext } from '../App';
import config from "../config.json";
import { addCircle, addOutline, arrowDownOutline, closeCircle, ellipseOutline, ellipsisHorizontal, handLeftOutline, personOutline, searchCircle, searchOutline } from 'ionicons/icons';
import SearchResults from '../components/SearchResults';
import { useCookies } from 'react-cookie';

const Tab1: React.FC = () => {
  const history=useHistory();
  const [items, setItems] = useState([]);
  const [kinkList, setKinkList] = useState([]);
  const [domList, setDomList] = useState([]);
  const [subList, setSubList] = useState([]);
  const [queryString, setQueryString] = useState("");
  const [sortBy, setSortBy] = useState<string>("date");
  const {appContext,setAppContext}= useContext(AppContext);
  const {lang,setLang}=useContext(LanguageContext);
  const {videoQuality,setVideoQuality}=useContext(VideoQualityContext);
  const [cookies, setCookie] = useCookies(['regionApproved', 'language'])

  
  const [modalMode, setModalMode] = useState('kink');
  const [searchActive, setSearchActive] = useState(false);
  const modal = useRef<HTMLIonModalElement>(null);

  function confirm() {
    modal.current?.dismiss();
  }

  function openModal() {
    modal.current.present();
  }


  function addItem(id){
    if(modalMode=="dom"){
      domList.push(id);
        setDomList([...domList])
    }
    if(modalMode=="sub"){
      subList.push(id);
        setSubList([...subList])
    }
    if(modalMode=="kink"){
      kinkList.push(id);
        setKinkList([...kinkList])
    }
}
function removeItem(id,mode=""){
  if(mode==""){
    mode=modalMode
  }
  if(mode=="sub"){
      let temp=subList
      temp.splice(subList.indexOf(id),1)
      setSubList([...temp])
  }
  if(mode=="dom"){
      let temp=domList
      temp.splice(domList.indexOf(id),1)
      setDomList([...temp])
  }
  if(mode=="kink"){
      let temp=kinkList
      temp.splice(kinkList.indexOf(id),1)
      setKinkList([...temp])
  }
}
function itemInList(id){
    let currentList=[]
    if(modalMode=="kink"){
        currentList=kinkList
    }
    if(modalMode=="dom"){
        currentList=domList
    }
    if(modalMode=="sub"){
        currentList=subList
    }
    return currentList.indexOf(id)>-1
}

  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  useEffect(function(){
    if(Object.keys(appContext.kink).indexOf(searchParams.get("kink"))>-1){
      setKinkList([searchParams.get("kink")])  
    }

    if(searchParams.get("query")){
      setQueryString(searchParams.get("query"))  
    }
  },[])
  


  const [pageTitle,setPageTitle] = useState("Albums")
  useEffect(() => {
    document.title = pageTitle+' - JIZBAR';
  }, [pageTitle]);

  return (
    <IonPage>
      <IonHeader>
      <IonToolbar>
        <IonSearchbar debounce={1000} value={queryString} className='home_searchbar' showCancelButton="focus" cancelButtonText={T8(lang,"Cancel")} placeholder={T8(lang,"Search")}
        onIonChange={e=>setQueryString(e.target.value)}
        onIonClear={e=>setSearchActive(false)}
        onIonFocus={e=>setSearchActive(true)}>
        </IonSearchbar>
        {searchActive&&<IonCard key="592" style={{paddingTop:0, marginTop:0}}>
          <IonItem><h4>{T8(lang,"Filters")}</h4></IonItem>
          <IonItem >
            <IonLabel>{T8(lang,"Curator / Dom")}</IonLabel>
            <IonButton color="success" onClick={e=>{openModal();setModalMode("dom")}}><IonIcon icon={addOutline}></IonIcon></IonButton>
          </IonItem>
          {domList.length>0&&<IonItem key="placeholder">
              {domList.map((item)=>(<IonChip onClick={e=>removeItem(item,"dom")}>{appContext.profile[item]['name'][lang]}</IonChip>))}
            </IonItem>}
          <IonItem>
            <IonLabel>{T8(lang,"Model / Sub")}</IonLabel>
            <IonButton color="success" onClick={e=>{openModal();setModalMode("sub")}}><IonIcon icon={addOutline}></IonIcon></IonButton>
          </IonItem>
          {subList.length>0&&<IonItem key="placeholder">
              {subList.map((item)=>(<IonChip onClick={e=>removeItem(item,"sub")}>{appContext.profile[item]['name'][lang]}</IonChip>))}
            </IonItem>}
          <IonItem>
            <IonLabel>{T8(lang,"Kink")} / {T8(lang,"Theme")}</IonLabel>
            <IonButton color="success" onClick={e=>{openModal();setModalMode("kink")}}><IonIcon icon={addOutline}></IonIcon></IonButton>
          </IonItem>
          {kinkList.length>0&&<IonItem key="placeholder">
              {kinkList.map((item)=>(<IonChip onClick={e=>removeItem(item,"kink")}>{appContext.kink[item][lang]}</IonChip>))}
            </IonItem>}
            <IonItem>
              <IonLabel>{T8(lang,"Sort By")}</IonLabel>
              <IonSelect onIonChange={e=>setSortBy(e.target.value)} value={sortBy} interface="action-sheet">
                <IonSelectOption value="date">{T8(lang,"Latest")}</IonSelectOption>
                <IonSelectOption value="view">{T8(lang,"Most Viewed")}</IonSelectOption>
                <IonSelectOption value="likes">{T8(lang,"Most Liked")}</IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel>{T8(lang,"Language")}</IonLabel>
              <IonSelect onIonChange={e=>{setLang(e.target.value); setCookie('language',e.target.value);}} value={lang} interface="action-sheet">
                <IonSelectOption value="en">Engliish</IonSelectOption>
                <IonSelectOption value="zh">中文</IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem onContextMenu={e=>{
                        let temp=appContext
                        let userinput=String(prompt("Admin Code?"));
                        temp.admin=userinput
                        setAppContext(temp)
                        setQueryString("__"+userinput)
                    }}>
              <IonLabel>{T8(lang,"Video Quality")}</IonLabel>
              <IonSelect onIonChange={e=>setVideoQuality(e.target.value)} value={videoQuality} interface="action-sheet">
                <IonSelectOption value={426}>240p</IonSelectOption>
                <IonSelectOption value={854}>480p</IonSelectOption>
                <IonSelectOption value={1920}>Original</IonSelectOption>
              </IonSelect>
            </IonItem>
        </IonCard>
        }
      </IonToolbar>
      
      </IonHeader>
      <IonContent fullscreen onIonScrollStart={e=>{setSearchActive(false);}}  onIonScroll={e=>{setSearchActive(false);}}>
        <IonList onTouchStart={e=>{setSearchActive(false);}} onClick={e=>{setSearchActive(false);}}>
          <IonListHeader>
            <IonLabel>
              <IonText><h1>{T8(lang,"Albums")}</h1></IonText>
            </IonLabel>
          </IonListHeader>
          <>
          <SearchResults name={queryString} dom={domList} sub={subList} kink={kinkList} sort={sortBy}></SearchResults>
          </>
          </IonList>
          <IonModal ref={modal} >
                    <IonHeader>
                        <IonToolbar className='ion-text-center'>
                            <IonTitle>
                              {modalMode == "kink" && T8(lang,"Kink")}
                              {modalMode == "sub" && T8(lang,"Model / Sub")}
                              {modalMode == "dom" && T8(lang,"Curator / Dom")}
                            </IonTitle>
                            <IonButtons slot="end">
                                <IonButton strong={true} onClick={() => confirm()}>
                                    {T8(lang,"Done")}
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className="ion-padding">
                            {modalMode == "kink" && Object.keys(appContext.kink).map((k)=>(
                              itemInList(k)?
                                <IonButton shape='round' color="success" onClick={e=>removeItem(k)}>{appContext.kink[k][lang]} <IonIcon icon={closeCircle}></IonIcon></IonButton>:
                                <IonButton shape='round' onClick={e=>addItem(k)}>{appContext.kink[k][lang]} <IonIcon icon={addCircle}></IonIcon></IonButton>
                            ))}

                            {modalMode == "sub" && appContext.sub.map((k)=>(
                              itemInList(k)?
                                <IonButton shape='round' color="success" onClick={e=>removeItem(k)}>{appContext.profile[k]['name'][lang]} <IonIcon icon={closeCircle}></IonIcon></IonButton>:
                                <IonButton shape='round' onClick={e=>addItem(k)}>{appContext.profile[k]['name'][lang]} <IonIcon icon={addCircle}></IonIcon></IonButton>
                            ))}

                            {modalMode == "dom" && appContext.dom.map((k)=>(
                              itemInList(k)?
                                <IonButton shape='round' color="success" onClick={e=>removeItem(k)}>{appContext.profile[k]['name'][lang]} <IonIcon icon={closeCircle}></IonIcon></IonButton>:
                                <IonButton shape='round' onClick={e=>addItem(k)}>{appContext.profile[k]['name'][lang]} <IonIcon icon={addCircle}></IonIcon></IonButton>
                            ))}
                    </IonContent>
                </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Tab1;
