import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonGrid, IonHeader, IonIcon, IonList, IonPage, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { atCircle, atOutline, informationCircle, logoTwitch, logoTwitter, paperPlane, paperPlaneOutline, pencilOutline, pencilSharp } from 'ionicons/icons';
import { useContext, useEffect, useState } from 'react';
import { LanguageContext, T8} from '../App';
import './Tab1.css';

const Tab3: React.FC = () => {
  const [pageTitle,setPageTitle] = useState("About - JIZBAR")
  useEffect(() => {
    document.title = pageTitle+' - JIZBAR';
  }, [pageTitle]);
  const {lang} = useContext(LanguageContext);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{T8(lang,"Contact")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{T8(lang,"Contact")}</IonTitle>
          </IonToolbar>
        </IonHeader>
<div className='ion-padding'>
          <div className='grid'>
          <IonCard className='grid_item'>
            <div className='headerImage applyForSession'></div>
            <IonCardHeader>
              <IonCardTitle>{T8(lang,"Apply for a session")}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <p>{T8(lang,"Provide a few details and let me get back to you.")}</p>
              <p>{T8(lang,"Both Dom and Sub are welcomed")}</p>
              <br></br>
              <IonButton  target="_blank" href='https://docs.google.com/forms/d/e/1FAIpQLSf1mXIYRcYFp7BdaWPRMrElFEjTy-rCaHtwgqCicD7ohIq1Ug/viewform?usp=sf_link'><IonIcon icon={paperPlane}></IonIcon> {T8(lang,"Apply Now")}</IonButton>
              <IonButton routerLink='/profile/jizbar' routerDirection='root'><IonIcon icon={informationCircle}></IonIcon> {T8(lang,"Learn about me")}</IonButton>
            </IonCardContent>
          </IonCard>

          <IonCard className='grid_item' target="_blank"  href={'https://twitter.com/messages/compose?recipient_id=2527662379&text='+encodeURIComponent(T8(lang,"Hello, I am ____. Would like to ask "))}>
            <IonCardHeader>
            <IonCardTitle>{T8(lang,"Other enquiries")}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <p>{T8(lang,"Please drop your question in full and directly on Twitter.")}</p>
              <p>{T8(lang,"Questions about uncensored media, personal details of models will not be responded.")}</p>
              <p>{T8(lang,"Start your conversation with something more than 'hi' 🙂")}</p>

              <br></br>
              <IonButton><IonIcon icon={logoTwitter}></IonIcon> {T8(lang,"Pop me a message")}</IonButton>
            </IonCardContent>
          </IonCard>

          <IonCard className='grid_item' target="_blank"  href='mailto:jizbar@outlook.com'>
            <IonCardHeader>
            <IonCardTitle>{T8(lang,"Email")}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <p>{T8(lang,"Contact me through email if you don't have Twitter")}</p>
              <p>jizbar<IonIcon icon={atOutline}></IonIcon>outlook.com</p>
            </IonCardContent>
          </IonCard>
          </div>
          </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab3;
