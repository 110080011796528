import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonPage, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonSlide, IonSlides, IonSpinner, IonText, IonTitle, IonToolbar, useIonToast, useIonViewWillLeave } from '@ionic/react';
import './Tab1.css';
import { useHistory, useParams } from 'react-router';
import { useLocation } from 'react-router-dom'
import { useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { AppContext, LanguageContext, sendGetRequest, T8, VideoQualityContext } from '../App';
import config from "../config.json";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { addCircle, calendar, checkmarkCircle, closeSharp, eyeOutline, handLeftOutline, heart, personOutline } from 'ionicons/icons';
import LikeButton from '../components/LikeButton';
import CardAd from '../components/AdContent';


const AlbumView: React.FC = () => {
  const params = useParams<{id?:string}>();
  const history=useHistory();
  const [items, setItems] = useState([]);
  const [albumData, setAlbumData] = useState({});
  const [blockCloseModal, setBlockCloseModal] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [ad_id, setAdId] = useState(0);
  const [lastHistory, setLastHistory] = useState(history.location);
  const [modalImage, setModalImage] = useState("");
  const {lang}=useContext(LanguageContext);
  const {videoQuality}=useContext(VideoQualityContext);
  const {appContext}=useContext(AppContext);
  const modal = useRef<HTMLIonModalElement>(null);
  const [present] = useIonToast();
  const [pageTitle,setPageTitle] = useState(T8(lang,"Album"))
  const ads=[{
    bgimg:'/assets/siroko_bg.jpeg',
    img:'/assets/siroko.png',
    title:{
      en:'10% off professional cycling gears',
      zh:'專業單車緊身衣 9 折優惠'
    },
    text:{
      en:'Get yourself a piece of professional gear from Siroko for your next session, without breaking the bank!',
      zh:'來自北歐的 Siroko 專營高性價比的滑雪及自行車的專業裝備，入手一件緊身車衣，為你下一次的約會添上新主題吧'
    },
    link:"https://srko.co/jizbar"
    },{
      bgimg:'/assets/ig_follow.jpg',
      img:'',
      title:{
        en:'Follow my Instagram',
        zh:'追蹤我的 Instagram'
      },
      text:{
        en:'Don\'t miss out on new album release, follow our Instagram page',
        zh:'不想錯過新的相集，按此打開我的 Instagram 專頁吧'
      },
      link:"https://ig.me/m/jizbar.rope?ref=w19688148"
      },{
        bgimg:'/assets/applyforsession.jpg',
        img:'/assets/shopback_logo.svg',
        title:{
          en:'Apply for Session',
          zh:'報名一起玩'
        },
        text:{
          en:'Provide a few details and let me get back to you. Both Dom and Sub are welcomed',
          zh:'請留下一點有關你的資料，我會儘快給你回覆，主和奴都歡迎聯絡遊玩交流'
        },
        link:"https://docs.google.com/forms/d/e/1FAIpQLSf1mXIYRcYFp7BdaWPRMrElFEjTy-rCaHtwgqCicD7ohIq1Ug/viewform?usp=sf_link"
        },{
          bgimg:'/assets/shopback.jpg',
          img:'/assets/shopback_logo.svg',
          title:{
            en:'🇭🇰 Get cashback on hotel bookings',
            zh:'🇭🇰 開房定叫外賣都賺現金回贈'
          },
          text:{
            en:'Get up to 10% reward by booking hotel as usual on Booking.com / Agoda etc. with Shopback, register now for 30 HKD signup bonus.',
            zh:'經過 Shopback 去外賣平台落單、訂酒店、或者上淘寶買玩具，都可以賺取高達 10% 現金回贈。依家註冊額外送你 30 HKD。'
          },
          link:"https://app.shopback.com/yKdS4OanWxb"
          }]
  useEffect(() => {
    sendGetRequest('get',config[process.env.NODE_ENV].ENDPOINT+'/getAlbum/'+params.id).then(data => {
      if(data?.album.length==0){
        
        present({
          message: T8(lang,'Album does not exist'),
          duration: 15000,
          position: 'bottom',
          cssClass: 'errorToast',
          icon: 'warning'
        });
      }else{
      setItems(data?.images)
      setAlbumData(data?.album[0])
      setLoaded(true)
      setPageTitle(data?.album[0]?.['title'][lang])
      setAdId(Math.floor(Math.random()*ads.length))
      //console.log(data)
      }
    }).catch(error => {
      console.log(error)
      present({
        message: T8(lang,'Service unavailable'),
        duration: 15000,
        position: 'bottom',
        cssClass: 'errorToast',
        icon: 'warning'
      });
})
  }, []);

  useEffect(() => {
    document.title = pageTitle+' - JIZBAR';
    
	// Add the new META-Tags
  	// Try to remove META-Tags
	try{
	  document.querySelector("meta[name='og:title']").remove();
	} catch (e){

	}
	var description = document.createElement('meta');
	description.name = "og:title";
	description.content = pageTitle+' - JIZBAR';
	document.getElementsByTagName('head')[0].appendChild(description);

	try{
	  document.querySelector("meta[name='twitter:title']").remove();
	} catch (e){

	}
	var description = document.createElement('meta');
	description.name = "twitter:title";
	description.content = pageTitle+' - JIZBAR';
	document.getElementsByTagName('head')[0].appendChild(description);


  }, [pageTitle]);

  useEffect(() => {
    try{
      document.querySelector("meta[name='og:image']").remove();
    } catch (e){
  
    }
    var description = document.createElement('meta');
    description.name = "og:image";
    description.content = "https:"+config[process.env.NODE_ENV].SD_DIR+albumData?.['cover'];
    document.getElementsByTagName('head')[0].appendChild(description);
  
    try{
      document.querySelector("meta[name='twitter:image']").remove();
    } catch (e){
  
    }
    var description = document.createElement('meta');
    description.name = "twitter:image";
    description.content = "https:"+config[process.env.NODE_ENV].SD_DIR+albumData?.['cover'];
    document.getElementsByTagName('head')[0].appendChild(description);
  
    }, [albumData]);

  useEffect(() => {
    document.title = pageTitle+' - JIZBAR';
  }, [pageTitle]);


  
    useIonViewWillLeave(() =>{

      console.warn('willelave')
      modal.current?.dismiss();

    })
  
  return (
    <IonPage>
      <IonHeader>
      <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton defaultHref='/app/album'/>
          </IonButtons>
          
          <IonTitle>{loaded?albumData?.['title'][lang]:"..."}</IonTitle>
          
        </IonToolbar>
      
      </IonHeader>
      <IonContent fullscreen>
        {loaded && <div style={{paddingTop:"12px",paddingBottom:"12px"}}>

          
          <IonGrid style={{fontSize:"0.8em"}}>
            <IonRow><IonCol><IonText >{albumData?.['description']?.[lang]}</IonText></IonCol></IonRow>

            <IonRow>{albumData?.['kink'].map((k)=>(
                              <IonButton size="small" shape='round' color={"medium"} style={{fontSize:"0.8em"}} routerDirection="root" routerLink={'/app/album?kink='+k}>{appContext.kink?.[k]?.[lang]}</IonButton>
                            ))}</IonRow>
            
            <IonRow>
              <IonCol>
                <IonText color="medium"><IonIcon icon={eyeOutline}></IonIcon> {albumData?.['views']}</IonText> · 
                <IonText color="medium"><IonIcon icon={heart}></IonIcon> {albumData?.['likes']}</IonText> · 
                <IonText color="medium"><IonIcon icon={calendar}></IonIcon> {new Date(albumData?.['timestamp']*1000).toISOString().split('T')[0]}</IonText>
              </IonCol>
            </IonRow>
            
            <IonRow>
              <IonCol>
                <IonText><h4>{T8(lang,"Model / Sub")}</h4></IonText>
                {albumData?.['sub'].map((k)=>(
                              <IonButton size="small" shape='round' style={{fontSize:"0.8em"}} routerLink={"/profile/"+k} routerDirection="forward">{appContext.profile?.[k]['name'][lang]}</IonButton>
                            ))}
              </IonCol>
              <IonCol>
                <IonText><h4>{T8(lang,"Curator / Dom")}</h4></IonText>
                {albumData?.['dom'].map((k)=>(
                              <IonButton size="small" shape='round' style={{fontSize:"0.8em"}} routerLink={"/profile/"+k} routerDirection="forward">{appContext.profile?.[k]['name'][lang]}</IonButton>
                            ))}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <center>
                <div style={{maxWidth:"800px", textAlign:"left"}}>
                  <CardAd bgimg={ads[ad_id].bgimg} img={ads[ad_id].img} 
                    title={ads[ad_id].title[lang]} 
                    text={ads[ad_id].text[lang]} 
                    link={ads[ad_id].link}
                  ></CardAd></div>
                  </center>
              </IonCol>
            </IonRow>
          </IonGrid>
         

        </div>}
        <IonList>
          <div className='grid'>
          {!loaded  ?
            <center><IonSpinner></IonSpinner></center> :
            
            items.map((item) => (<div className='grid_item'><IonCard key={item.id}>
                {item.file_name.indexOf("twitter.com")>-1?
                <TwitterTweetEmbed
                tweetId={item.file_name.split('/').slice(-1)[0]}
              />:
                item.file_name.toLocaleLowerCase().indexOf(".mp4")>-1?
                <video src={config[process.env.NODE_ENV].VIDEODIR+String(videoQuality)+"/"+item.file_name} style={{width:"100%"}} autoPlay={false} controls onContextMenu={e=>{console.log(e);if(appContext.admin){console.log(appContext.admin);;launchImageModal(item.file_name)}else{promptResolutionChange()}}}></video>:
                <IonImg src={config[process.env.NODE_ENV].SD_DIR+item.file_name} onClick={e=>launchImageModal(item.file_name)}></IonImg>}
              {item.description[lang] && <IonCardContent>{item.description[lang]}</IonCardContent>}
            </IonCard></div>))
          }
          </div>

          <br></br>
                      <br></br>
                      <br></br>
                      <br></br>
                      <br></br>
          </IonList>

          <div className="ion-text-right ion-padding floatbr" >
            {loaded && <LikeButton albumData={albumData} album_id={params.id}></LikeButton>}
          </div>
          <IonModal ref={modal} onClick={()=>{closeModal();}} onMouseMove={blockCloseModalEvt} className="fullscreenModal" >
                    <IonContent fullscreen className='fullscreen'>
                      <div className="ion-padding float-top-left">

                      <IonButton color={"dark"} fill="clear" strong={false} onClick={() => closeModal(true)} >
                        <h2><IonIcon size='large' icon={closeSharp}></IonIcon></h2>
                    </IonButton>   
                    {appContext.admin && <>
                      <IonButton color={"dark"} fill="outline" strong={false} onClick={e => setCover()}>
                        Set Cover
                      </IonButton>  
                      <IonButton color={"dark"} fill="outline" strong={false} onClick={e => removeImage()}>
                        Remove Image
                      </IonButton>  
                      <IonButton color={"dark"} fill="outline" strong={false} onClick={e => setPriority()}>
                        Set Priority
                      </IonButton>  
                      <IonButton color={"dark"} fill="outline" strong={false} onClick={e => setTimestamp()}>
                        Set Timestamp
                      </IonButton>  
                      <IonButton color={"dark"} fill="outline" strong={false} onClick={e => setDesc(lang)}>
                        Set Desc
                      </IonButton>   
                      <IonButton color={"dark"} fill="outline" strong={false} onClick={e => setAlbumDesc(lang)}>
                        Set AlbumDesc
                      </IonButton>  
                      <IonButton color={"dark"} fill="outline" strong={false} onClick={e => setTitle(lang)}>
                        Set Title
                      </IonButton>  
                      <IonButton color={"dark"} fill="outline" strong={false} onClick={e => addTag()}>
                        Add Tag
                      </IonButton> 
                    </>}
                      </div>
                    <TransformWrapper limitToBounds={false}>
                      <TransformComponent>
                        <IonImg src={config[process.env.NODE_ENV].HD_DIR+modalImage} style={{width:"100%"}} onLoad={e=>{}}></IonImg> 
                      </TransformComponent>
                    </TransformWrapper>
                    </IonContent>
                </IonModal>

      </IonContent>
    </IonPage>
  );
  function setCover(){
    sendGetRequest('post',config[process.env.NODE_ENV].ENDPOINT+'/setCover',{
      password: appContext.admin,
      cover: modalImage,
      id: params.id 
    }).then(data => {
      present({
        message: JSON.stringify(data),
        duration: 15000,
        position: 'bottom',
        icon: 'checkmarkCircle'
      })
    });

  }
  function removeImage(){
    sendGetRequest('post',config[process.env.NODE_ENV].ENDPOINT+'/removemedia',{
      password: appContext.admin,
      file_name: modalImage
    }).then(data => {
      present({
        message:  JSON.stringify(data),
        duration: 15000,
        position: 'bottom',
        icon: 'checkmarkCircle'
      })
    });
  }
  function setPriority(){
    sendGetRequest('post',config[process.env.NODE_ENV].ENDPOINT+'/setPriority',{
      password: appContext.admin,
      order_id: parseInt(prompt('Proitiy (number int)')),
      file_name: modalImage
    }).then(data => {
      present({
        message:  JSON.stringify(data),
        duration: 15000,
        position: 'bottom',
        icon: 'checkmarkCircle'
      })
    });
  }

  function setDesc(lang){
    sendGetRequest('post',config[process.env.NODE_ENV].ENDPOINT+'/setDescription',{
      password: appContext.admin,
      description: (prompt('comment')),
      file_name: modalImage,
      lang:lang
    }).then(data => {
      present({
        message:  JSON.stringify(data),
        duration: 15000,
        position: 'bottom',
        icon: 'checkmarkCircle'
      })
    });
  }
  function setAlbumDesc(lang){
    sendGetRequest('post',config[process.env.NODE_ENV].ENDPOINT+'/setAlbumDescription',{
      password: appContext.admin,
      description: (prompt('desc')),
      album_id: params.id,
      lang:lang
    }).then(data => {
      present({
        message:  JSON.stringify(data),
        duration: 15000,
        position: 'bottom',
        icon: 'checkmarkCircle'
      })
    });
  }
  function setTitle(lang){
    sendGetRequest('post',config[process.env.NODE_ENV].ENDPOINT+'/setAlbumTitle',{
      password: appContext.admin,
      title: (prompt('title')),
      album_id: params.id,
      lang:lang
    }).then(data => {
      present({
        message:  JSON.stringify(data),
        duration: 15000,
        position: 'bottom',
        icon: 'checkmarkCircle'
      })
    });
  }


  function addTag(){
    sendGetRequest('post',config[process.env.NODE_ENV].ENDPOINT+'/addTag',{
      password: appContext.admin,
      tag: (prompt( Object.keys((appContext.kink)).join(',') )),
      id: params.id,
      lang:lang
    }).then(data => {
      present({
        message:  JSON.stringify(data),
        duration: 15000,
        position: 'bottom',
        icon: 'checkmarkCircle'
      })
    });
  }



  function setTimestamp(){
    sendGetRequest('post',config[process.env.NODE_ENV].ENDPOINT+'/setTimestamp',{
      password: appContext.admin,
      timestamp: Math.floor(new Date(prompt('date')).getTime()/1000),
      id: params.id
    }).then(data => {
      present({
        message:  JSON.stringify(data),
        duration: 15000,
        position: 'bottom',
        icon: 'checkmarkCircle'
      })
    });
  }

  function closeModal(force=false) {
    if(!blockCloseModal||force){
    modal.current?.dismiss();
    if(history.location.hash=='#zoom'){
      history.replace('#')
    }
  }
    setBlockCloseModal(false)
  }
  function blockCloseModalEvt() {
    if(!blockCloseModal){
    setBlockCloseModal(true)
    }
  }


  function launchImageModal(string){
    console.log('launch image modal')
    console.log('string')
    modal.current.present();
    setModalImage(string)
    sendGetRequest('post',config[process.env.NODE_ENV].ENDPOINT+'/mediaZoom',{
      image_id: string,
      album_id: params.id
    }).then(data => {
      //console.log('zoom',data)
    });
  }
  function promptResolutionChange(){
    console.log('reso change')
  }
};

export default AlbumView;
