import { IonCard, IonCardContent, IonItem, IonThumbnail, IonImg, IonLabel, IonBadge, IonText, IonCol, IonRow, IonCardHeader, IonCardTitle, IonGrid } from '@ionic/react';
import './ExploreContainer.css';

interface ContainerProps {
    bgimg:string;
  img: string;
  title: string;
  text: string;
  link: string
}

const CardAd: React.FC<ContainerProps> = ({ bgimg, img, title, text, link }) => {
  return (
    <IonCard href={link} target="_blank">
               <div className={"adheaderImage"} 
                    style={{backgroundImage:"url('"+bgimg+"')"}}>
                 <IonBadge color="medium" className='issuer-badge' style={{margin:"10px", position:"absolute"}}><IonText>Ad</IonText></IonBadge>
                <center>{img ? <IonImg src={img} style={{maxWidth:"200px", maxHeight:"110px", padding:'20px'}}></IonImg> : <div style={{height:"100px",width:"100px", display:"block"}}></div>}</center>
              <IonCardHeader className='dimblurBackground'>
                <IonCardTitle>{title}</IonCardTitle>
                <div color="medium" style={{fontSize:"0.9em", lineHeight:"1.5em"}}>
                {text}</div>

                
              </IonCardHeader>
              </div>
            </IonCard>
  );
};

export default CardAd;
