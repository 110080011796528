import { IonSpinner, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonIcon, IonCardContent, IonGrid, IonCol, IonRow, IonText, IonButton } from '@ionic/react';
import axios from 'axios';
import { personOutline, handLeftOutline, heart, heartOutline, water, linkOutline, copyOutline } from 'ionicons/icons';
import { useContext, useEffect, useState } from 'react';
import { AppContext, LanguageContext, sendGetRequest, T8 } from '../App';
import config from "../config.json";
import './ExploreContainer.css';
import '../pages/Tab1.css';
import { TwitterShareButton } from 'react-twitter-embed';

interface ContainerProps {
album_id:string,
albumData:any
}

const LikeButton: React.FC<ContainerProps> = ({album_id,  albumData}) => {

    const {lang}=useContext(LanguageContext);

    const [likeLoaded, setLikeLoaded] = useState(false);
    const [liked, setLiked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [copytext, setCopytext] = useState(false);
    const [adjustor, setAdjustor] = useState(0);
    useEffect(() => {
        sendGetRequest('get',config[process.env.NODE_ENV].ENDPOINT+'/liked_album/'+album_id).then(data => {
          setLiked(data?.liked);
          setAdjustor(1)
          setLikeLoaded(true)
        });
      }, []);
      
      function likeAlbum(){
        setLoading(true)
        sendGetRequest('get',config[process.env.NODE_ENV].ENDPOINT+'/like_album/'+album_id).then(data => {
          //console.log(data);
          setLiked(data?.liked>0)
          setLoading(false)
        });
      }
  return (

    <>
        {likeLoaded && <IonRow><IonCol className="twitter-share" style={{textAlign:"right"}}>
            <TwitterShareButton

      onLoad={function noRefCheck(){}}
      options={{
        text: albumData?.['title']?.[lang]||"",
        via: 'JIZBAR',
        size: 'large'
      }}
      url={"https://s.jizbar.com/album/"+ (album_id||"")}
    />
   
                <IonButton  onClick={() => {setCopytext(true);setTimeout(function(){setCopytext(false)},5000);navigator.clipboard.writeText("https://s.jizbar.com/album/"+(album_id||""))}}>{(!copytext)?<span><IonIcon icon={linkOutline}></IonIcon> <IonIcon icon={copyOutline}></IonIcon></span>:<span>{T8(lang,"Copied")}</span>}</IonButton>
                {liked ? 
                 <IonButton disabled>💦 {T8(lang,"You and")} {albumData?.['likes']} {T8(lang,"JIZ'd")}</IonButton>:
                 <IonButton onClick={e=>likeAlbum()}>💦 {albumData?.['likes']} {T8(lang,"LIKE")}</IonButton>
                }{loading && <IonSpinner></IonSpinner>}
              </IonCol></IonRow>}
            
          </>
  );
};
export default LikeButton;


          