import { IonAvatar, IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonPage, IonRow, IonSearchbar, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonSlide, IonSlides, IonSpinner, IonText, IonTitle, IonToolbar, useIonToast } from '@ionic/react';
import './Tab1.css';
import { useHistory, useParams } from 'react-router';
import { useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { AppContext, LanguageContext, sendGetRequest, T8 } from '../App';
import config from "../config.json";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { addCircle, calendar, eyeOutline, globeOutline, handLeftOutline, logoFacebook, logoInstagram, logoTwitter, personOutline } from 'ionicons/icons';
import SearchResults from '../components/SearchResults';

const ProfileView: React.FC = () => {
  const params = useParams<{id?:string,role?:string}>();
  const history=useHistory();
  const [profileData, setProfileData] = useState({});
  
  const [loaded, setLoaded] = useState(false);
  const [roleFilter, setRoleFilter] = useState(params.role?(params.role=="dom"?"2":"0"):"1");
  
  const {lang}=useContext(LanguageContext);
  const {appContext}=useContext(AppContext);
  const [present] = useIonToast();

  useEffect(() => {
    sendGetRequest('get',config[process.env.NODE_ENV].ENDPOINT+'/getProfile/'+params.id).then(data => {
        if(data.length>0){
            setProfileData(data[0])
            setLoaded(true)
            setPageTitle(data[0]?.['name'][lang])
        }else{
            present({
              message: T8(lang,'Profile not found'),
              duration: 15000,
              position: 'bottom',
              icon: 'warning'
            });        }
    }).catch(error => {
          present({
            message: T8(lang,'Service unavailable'),
            duration: 15000,
            position: 'bottom',
            icon: 'warning'
          });
    });
  }, []);

  const [pageTitle,setPageTitle] = useState(T8(lang,"Profile")+" - JIZBAR")
  useEffect(() => {
    document.title = pageTitle+' - JIZBAR';
  }, [pageTitle]);


  return (
    <IonPage>
      <IonHeader>
      <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton defaultHref="/app/"/>
          </IonButtons>
          
            <IonTitle>{loaded?profileData?.['name'][lang]:"..."}</IonTitle>
          
        </IonToolbar>
      
      </IonHeader>
      <IonContent fullscreen>

      {!loaded &&
            <center><IonSpinner></IonSpinner></center>
          }
        {loaded && <div style={{paddingTop:"12px",paddingBottom:"12px"}}>
          <center>
          <IonAvatar style={{width:"100px", height:"100px"}}><IonImg src={config[process.env.NODE_ENV].MEDIADIR+profileData?.['icon']}></IonImg></IonAvatar>
          <br></br>
          {profileData?.['twitter'] && <a href={profileData?.['twitter']} target="_blank" className='profile_link_logo'>
            <IonText><IonIcon icon={logoTwitter}></IonIcon></IonText>
          </a>}
          
          {profileData?.['facebook'] && <a href={profileData?.['facebook']} target="_blank" className='profile_link_logo'>
            <IonText><IonIcon icon={logoFacebook}></IonIcon></IonText>
          </a>}
          
          {profileData?.['instagram'] && <a href={profileData?.['instagram']} target="_blank" className='profile_link_logo'>
            <IonText><IonIcon icon={logoInstagram}></IonIcon></IonText>
          </a>}
          
          {profileData?.['website'] && <a href={profileData?.['website']} target="_blank" className='profile_link_logo'>
            <IonText><IonIcon icon={globeOutline}></IonIcon></IonText>
          </a>}
          
          <IonGrid style={{fontSize:"0.8em"}}>
            <IonRow><IonCol><IonText >{profileData?.['description']?.[lang]}</IonText></IonCol></IonRow>

            <IonRow><IonCol>{profileData?.['kink'].map((k)=>(
                              <IonButton size="small" shape='round' color={"medium"} style={{fontSize:"0.8em"}} routerDirection="root" routerLink={"/app/album?kink="+k}>{appContext.kink[k]?.[lang]||k+"[missing]"}</IonButton>
                            ))}</IonCol></IonRow>
          </IonGrid>
          <hr></hr>
          </center>
          <IonSegment value={roleFilter} onIonChange={e=>setRoleFilter(e.detail.value)}>
            <IonSegmentButton value="1">
              <IonLabel>All</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="2">
              <IonLabel>Dom</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="0">
              <IonLabel>Sub</IonLabel>
            </IonSegmentButton>
          </IonSegment>         
          <SearchResults dom={parseInt(roleFilter)>=1?[params.id]:[]} sub={parseInt(roleFilter)<=1?[params.id]:[]}></SearchResults>

        </div>}
          
      </IonContent>
    </IonPage>
  );
};

export default ProfileView;
